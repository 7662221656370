.text-center
{
    text-align: center;
} 

.icon-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 200px;
  margin-top: 200px
}


@media (max-width: 1653px)
  {
    h3{
      font-size: 1.5rem;
    }
  }

  @media (max-width: 1480px)
  {
    h3{
      font-size: 1.4rem;
    }
  }

  @media (max-width: 1230px)
  {
    h3{
      font-size: 1.4rem;
    }
  }

  @media (max-width: 873px)
  {
    h3{
      font-size: 1.3rem;
    }
  }

  @media (max-width: 500px)
  {
    .icon-flex{
      flex-direction: column;
      margin-bottom: 20px;
      margin-top: 20px
    }
  }