@import url("https://cdn.rawgit.com/mfd/09b70eb47474836f25a21660282ce0fd/raw/e06a670afcb2b861ed2ac4a1ef752d062ef6b46b/Gilroy.css");

body {
  margin: 0;
  font-family: 'Gilroy', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body #fonts {
  font-family: 'Gilroy', sans-serif;
  color: #343434;
}

code {
  font-family: 'Gilroy', sans-serif;
}
