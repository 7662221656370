@import url(https://cdn.rawgit.com/mfd/09b70eb47474836f25a21660282ce0fd/raw/e06a670afcb2b861ed2ac4a1ef752d062ef6b46b/Gilroy.css);
body {
  margin: 0;
  font-family: 'Gilroy', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body #fonts {
  font-family: 'Gilroy', sans-serif;
  color: #343434;
}

code {
  font-family: 'Gilroy', sans-serif;
}

.text-center
{
    text-align: center;
} 

.icon-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 200px;
  margin-top: 200px
}


@media (max-width: 1653px)
  {
    h3{
      font-size: 1.5rem;
    }
  }

  @media (max-width: 1480px)
  {
    h3{
      font-size: 1.4rem;
    }
  }

  @media (max-width: 1230px)
  {
    h3{
      font-size: 1.4rem;
    }
  }

  @media (max-width: 873px)
  {
    h3{
      font-size: 1.3rem;
    }
  }

  @media (max-width: 500px)
  {
    .icon-flex{
      flex-direction: column;
      margin-bottom: 20px;
      margin-top: 20px
    }
  }
.footer-clean {
    padding: 50px 0;
    background-color: #f7f8f9;
    color: #4b4c4d;
}
.footer-clean h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 16px;
}
.footer-clean ul {
    padding: 0;
    list-style: none;
    line-height: 1.6;
    font-size: 14px;
    margin-bottom: 0;
    display: table;
}
.footer-clean ul a {
    color: inherit;
    text-decoration: none;
    opacity: 0.8;
}
.footer-clean ul a:hover,
.highlight-clean .buttons .btn:hover {
    opacity: 1;
}
.footer-clean .item.social,
.header-blue .phone-holder {
    text-align: right;
}
@media (max-width: 768px) {
    .footer-clean .item.social {
        text-align: center;
    }
}
.footer-clean .item.social > a {
    font-size: 24px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin-left: 10px;
    margin-top: 22px;
    color: inherit;
    opacity: 0.75;
}
.footer-clean .item.social > a:hover {
    opacity: 0.9;
}
@media (max-width: 991px) {
    .footer-clean .item.social > a {
        margin-top: 40px;
    }
}
.footer-clean .copyright {
    margin-top: 14px;
    margin-bottom: 0;
    font-size: 13px;
    opacity: 0.6;
}
@media (max-width: 767px) {
    .footer-clean .item {
        text-align: center;
        padding-bottom: 20px;
    }
    .footer-clean .item.social > a {
        margin-top: 10px;
    }
}

@media (max-width: 576px) {
    .footer-clean ul {
        margin: 20px auto;
    }
}
body #menu-custom {
    border-bottom: 0px solid rgba(34,36,38,.15);
}

.circle-login-button {
    background-color:#2EB67D;
    width: 100px;
    height: 40px;
    border-width: 0px;
    font-family: "Gilroy";
    border-radius: 50px;
    color: white;
    font-weight: 600;
    border-color: white
}

#menu-custom {
    margin-left: 100px; 
    margin-right: 100px
}

@media (max-width: 1014px)
{
  .non-mobile-menu
  {
    display: none;
  }
}

@media (min-width: 1015px)
{
  .mobile-menu
  {
    display: none;
  }
}

@media (min-width: 1700px)
{
  #menu-custom {
    margin-left: 300px; 
    margin-right: 300px
  }
}

@media (min-width: 1573px) and (max-width: 1699)
{
  #menu-custom {
    margin-left: 250px; 
    margin-right: 250px
  }
}
